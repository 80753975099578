<template>
  <div
    :id="PROJECT_NAME"
    :data-brand="BRAND"
    :data-market="MARKET"
    :data-development="DEVELOPMENT"
    :data-production="PRODUCTION"
    :data-vertical="VERTICAL"
    :data-build-name="BUILD_NAME"
    :data-build-date="BUILD_DATE"
    :data-build-hash="BUILD_HASH"
    :class="{ 'local': LOCAL }"
  >
    <lazy-hydrate never>
      <page-meta key="page-meta" />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Header                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate
      v-if="showNav"
      never
    >
      <fragment
        key="header"
        :custom-query="fragmentCustomQuery"
        name="header"
      />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Form                                                                                   -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate when-idle>
      <section
        :class="['cl-form', {
          'cl-form--with-nav': showNav,
          'cl-form--with-background': showBackground,
          'cl-form--loaded': isLoaded
        }]"
      >
        <routes
          key="route"
          @loaded="routeLoaded"
        />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-idle>
      <div>
        <zg-chat
          v-if="showChat"
          :brand="BRAND"
          :country="MARKET"
          type="zopim"
        />
        <event-listener />
        <authentication-listener v-if="!MORTGAGE" />
        <refresh-tokens-listener v-if="!MORTGAGE" />
        <loader />
        <modal />
      </div>
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Footer                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate
      v-if="showNav"
      never
    >
      <fragment
        key="footer"
        :custom-query="fragmentCustomQuery"
        name="footer"
      />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Themes                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate
      v-if="BROKER"
      never
    >
      <broker-theme />
    </lazy-hydrate>

    <lazy-hydrate
      v-if="WHITE_LABEL"
      never
    >
      <white-label-theme />
    </lazy-hydrate>
  </div>
</template>

<script>
  import { getUrlSearchParams, hasProp, hotjarTagRecording, setCookie, stateId } from '@ocp-zmarta/zmarta-cl'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapActions, mapGetters } from 'vuex'
  import PageMeta from './components/elements/_shared/misc/PageMeta'
  import { getCookie, getGaClientId, logInfo } from './utils'

  export default {
    name: 'App',
    components: {
      LazyHydrate,
      PageMeta,
      EventListener: () => import(/* webpackChunkName: 'components/elements/shared/listeners/event-listener' */
        './components/elements/_shared/listeners/EventListener'),
      AuthenticationListener: () => import(/* webpackChunkName: 'components/elements/cl/listeners/authentication-listener' */
        './components/elements/_shared/listeners/AuthenticationListener'),
      RefreshTokensListener: () => import(/* webpackChunkName: 'components/elements/cl/listeners/refresh-tokens-listener' */
        './components/elements/_shared/listeners/RefreshTokensListener'),
      Modal: () => import(/* webpackChunkName: 'components/elements/shared/misc/modal' */
        './components/elements/_shared/misc/Modal'),
      Loader: () => import(/* webpackChunkName: 'components/elements/shared/misc/loader' */
        './components/elements/_shared/misc/Loader'),
      Fragment: () => import(/* webpackChunkName: 'components/elements/shared/misc/fragment' */
        './components/elements/_shared/misc/Fragment'),
      Routes: () => import(/* webpackChunkName: 'components/routes' */
        './components/_routes/_Routes'),
      BrokerTheme: () => import(/* webpackChunkName: 'components/theme/broker-theme' */
        './components/theme/BrokerTheme'),
      WhiteLabelTheme: () => import(/* webpackChunkName: 'components/theme/white-label-theme' */
        './components/theme/WhiteLabelTheme'),
      ZgChat: () => import(/* webpackChunkName: 'zc/zg-chat' */
        '@zc/components/ZgChat/ZgChat.vue')
    },
    data: () => ({
      isLoaded: false,
      isActivatingExperiment: false
    }),
    computed: {
      ...mapGetters('router', ['getRoute', 'getQuery']),
      ...mapGetters('translations', ['getLocale', 'getIsDefaultLocale']),
      ...mapGetters('experiments', ['getExperiments']),
      isFragment () {
        return this.getRoute?.meta?.fragment === true
      },
      isInIframe () {
        return this.getQuery?.iframe
      },
      showNav () {
        return this.getRoute?.meta?.nav !== false && !this.isFragment && !this.isInIframe
      },
      showChat () {
        return this.getRoute?.meta?.chat !== false && !this.isFragment && !this.FINLAND && this.getIsDefaultLocale
      },
      showBackground () {
        return !this.isFragment && this.getRoute?.meta?.background !== false
      },
      fragmentCustomQuery () {
        return {
          locale: this.getLocale,
          op_user_id: this.getRoute?.userId,
          show_translations: true,
          ...(this.TESTCAFE ? { testcafe: true } : {})
        }
      }
    },
    async serverPrefetch () {
      await this.init()
    },
    async mounted () {
      await this.init()
      this.activateAfterGaCookie()

      hotjarTagRecording(`form/${this.getLocale}`)
      window.addEventListener('popstate', this.historyChanged)
    },
    beforeDestroy () {
      window.removeEventListener('popstate', this.historyChanged)
    },
    methods: {
      ...mapActions('modal', ['hideModal']),
      ...mapActions('loader', ['hideLoader']),
      ...mapActions('experiments', ['activateExperiment']),
      ...mapActions('zmartaSession', ['setSlugs']),
      async init () {
        await Promise.all([
          this.hideModal(),
          this.hideLoader()
        ])
      },
      async routeLoaded () {
        if (this.CL) {
          await this.$store.dispatch('cl/internalAccess/setInternalAccess')
          await this.$store.dispatch('cl/tracking/setTracking')
          await this.$store.dispatch('cl/tracking/setAdId')
          await this.$store.dispatch('cl/tracking/setOrganicTracking')
          await this.$store.dispatch('cl/tracking/setUtmParameters')
          await this.$store.dispatch('cl/tracking/setChannel')

          const brokerId = this.$store.getters['cl/tracking/getTracking']?.brokerId
          const autoLogin = !(this.NO_AUTO_LOGIN_BROKER_IDS.includes(brokerId) || this.WHITE_LABEL || this.isInIframe)
          const redirectToMyAccount = autoLogin && !hasProp(this.getQuery, 'no-redirect')

          await this.$store.dispatch('cl/misc/setAutoLogin', autoLogin)
          await this.$store.dispatch('cl/misc/setRedirectToMyAccount', redirectToMyAccount)
          await this.$store.dispatch('cl/formSteps/addThankYouStep')
          await this.$store.dispatch('cl/analytics/setReadyForAnalytics')

          setTimeout(async () => {
            await this.$store.dispatch('cl/tracking/setGaClientId')
          }, 2000)
        } else if (this.SME) {
          await this.$store.dispatch('sme/internalAccess/setInternalAccess')
          await this.$store.dispatch('sme/analytics/setReadyForAnalytics')
        } else if (this.MORTGAGE) {
          await this.$store.dispatch('mortgage/analytics/setReadyForAnalytics')
          this.setCrossSellReference()
        } else if (this.INSURANCE) {
          await this.$store.dispatch('insurance/analytics/setReadyForAnalytics')
        } else if (this.EPI) {
          await this.$store.dispatch('epi/analytics/setReadyForAnalytics')
        }

        this.setGaClickId()

        setTimeout(async () => {
          await this.updateSession()
        }, 2000)

        this.isLoaded = true
      },
      historyChanged (event) {
        this.hideModal()
        this.hideLoader()

        if (event?.state?.name === 'application' && event?.state?.step) {
          if (this.CL) this.$store.dispatch('cl/formSteps/changeStep', { step: event.state.step })
        }
      },
      activateAfterGaCookie () {
        const pvExperiment = this.getExperiments?.['cl-single-vs-multi-step']
        if (!pvExperiment || !this.CL) return

        const interval = setInterval(async () => {
          const cidCookie = getCookie('_ga')
          const sessionCookie = getCookie('_ga_EBQB54NM8T')

          if (cidCookie && sessionCookie && !this.isActivatingExperiment) {
            logInfo('mp-optimizely', {
              cid: getGaClientId()
            })

            this.isActivatingExperiment = true
            const activatedExperiment = await this.activateExperiment('cl-single-vs-multi-step')

            const mpEvent = this.ZGA.event.mp.optimizely({
              experimentId: activatedExperiment.id,
              experimentKey: activatedExperiment.experimentKey,
              variationId: activatedExperiment.variationsMap[activatedExperiment.variationKey] && activatedExperiment.variationsMap[activatedExperiment.variationKey].id,
              variationKey: activatedExperiment.variationKey
            })

            logInfo('mp-optimizely-sent', mpEvent)

            this.ZGA.event.experiment.optimizely({
              experimentId: activatedExperiment.id,
              experimentKey: activatedExperiment.experimentKey,
              variationId: activatedExperiment.variationsMap[activatedExperiment.variationKey] && activatedExperiment.variationsMap[activatedExperiment.variationKey].id,
              variationKey: activatedExperiment.variationKey
            })

            clearInterval(interval)
          }
        }, 200)
      },
      setGaClickId () {
        const params = getUrlSearchParams()
        const gclid = params?.gclid

        if (gclid) {
          window.sessionStorage?.setItem('gclid', gclid)
        }
      },
      updateSession () {
        if (this.getRoute?.meta?.fragment === true) return

        const serverState = window[stateId(this.PROJECT_NAME)]
        const slugs = serverState?.zmartaSession?.slugs

        this.setSlugs(slugs)
      },
      setCrossSellReference () {
        const params = getUrlSearchParams()
        const crossSellReference = params?.cross_sell_reference
        if (crossSellReference) {
          setCookie('cross_sell_reference', crossSellReference)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '@zc/scss/themes/zmarta/_global';
</style>

<style lang="scss" scoped>
  .local {
    min-height: 100vh;
    background-color: color(beige);
  }

  .cl-form {
    display: flex;
    flex-direction: column;

    &--with-nav {
      min-height: 100vh;
      padding-top: rem(56px);
    }

    &--with-background {
      background-color: color(beige);
    }
  }

  ::v-deep .zg-select.has-label > label {
    z-index: 0;
  }
</style>
